import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function GrowthMarketingLead () {
  return (
    <CareerLayout title='Growth marketing lead' location='Remote'>
      <h2>Overview</h2>
      <p className='mb'>As a Growth Marketing Lead for Trexity, you will look for opportunities to improve and come up with creative growth strategies from SEO to email marketing to finding brand new ways to help us continue to grow and reduce churn.</p>

      <h2>Responsibilities</h2>
      <ul className='trx-bullet mb'>
        <li>Implement tactics that will lead to the growth and maintenance of our driver pool and merchants across Canada</li>
        <li>Work alongside our sales team to support merchant outreach</li>
        <li>Work closely with our driver acquisition and retention team to build engagement programs</li>
        <li>Manage the creative strategy for Canada across all growth channels</li>
        <li>Build and scale creative offerings that drive growth for merchants and drivers</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>5+ years of related performance experience</li>
        <li>A proven track record on building, planning and implementing a marketing plan</li>
        <li>Experience with email and social media marketing platforms, google analytics, SEO and ad optimization</li>
        <li>Ability to test frameworks, measure impact and implement a scalable outcome</li>
        <li>You can synthesize data and make effective decisions, balanced with experience/intuition</li>
        <li>Knowledge of Google Analytics, Photoshop, Mailchimp, Hubspot (or, other Social Media Management Systems)</li>
        <li>Experience working in a double sided marketplace is a plus</li>
      </ul>

      <h2>About you</h2>
      <ul className='trx-bullet mb'>
        <li>A love for a high-paced, ever-changing environment</li>
        <li>Entrepreneurial—you are a self starter</li>
        <li>Creative with an ability to design and implement</li>
        <li>Detail oriented—analyzing outcomes and building programs based on experiments</li>
        <li>A strategic mind with an ability to see the forest and the trees</li>
        <li>Someone who loves to collaborate with team members across multiple areas</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
      </ul>
    </CareerLayout>
  )
}
